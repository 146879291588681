import './Mainpage.css';

function SectionResume() {
  return (
        <div id='SectionResume'>
            <div id='Resume'>
                <div id='APropos' className='section-name'>
                    <h2>À PROPOS DE MOI</h2>
                </div>
                <div id='ResumeInfos'>
                    <div id='ResumeInfosPhotoCadre'>
                        <div id='ResumeInfosPhoto'></div>
                    </div>

                    <div id='ResumeInfosText'>
                        <div>
                        Hello ! Diplômé d'un bachelor Développement (RNCP34022) à l’école Sup de Vinci à Bordeaux, je suis un développeur passionné ainsi qu'un féru amateur d'infographie 3D.
                        <br/><br/>Dans mon parcours académique ainsi que dans mes expériences professionnelles, j'ai eu l'opportunité d'approfondir mes connaissances dans divers langages de programmation, notamment PHP, JAVA et JS.

                        <br/><br/>Ces outils sont pour moi autant de pinceaux sur une palette, me permettant de donner vie à mes idées avec précision et créativité.
                        <br/><br/>Si vous recherchez un collaborateur passionné et polyvalent, capable de transformer vos concepts en réalité numérique,
                        n'hésitez pas à me contacter. 
                        <br/><br/>Je serais ravi de discuter de vos projets et de voir comment je peux contribuer à leur succès✌️
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default SectionResume;