import './Mainpage.css';
import DOMPurify from 'dompurify';

function SectionContact() {
    const handleSubmit = (event) => {
        event.preventDefault(); // Empêche le rechargement de la page

        // Récupérer les valeurs nettoyées des champs du formulaire
        const nom = DOMPurify.sanitize(event.target.elements.nom.value);
        const prenom = DOMPurify.sanitize(event.target.elements.prenom.value);
        const email = DOMPurify.sanitize(event.target.elements.email.value);
        const telephone = DOMPurify.sanitize(event.target.elements.telephone.value);
        const objet = DOMPurify.sanitize(event.target.elements.objet.value);
        const message = DOMPurify.sanitize(event.target.elements.message.value);
        const valdata = event.target.elements.valdata.checked;

        if (nom !== "" && email !== "" && objet !== "" && message !== "" && isEmailValid(email) && valdata == true) {

            // Créer l'objet de données à envoyer
            const data = {
                nom,
                prenom,
                email,
                telephone,
                objet,
                message,
                valdata
            };

            // Envoyer les données à votre serveur
            console.log(data);
            sendDataToServer(data);

        } else {
            // Mettre en évidence les champs manquants
            if (nom === "") {
                document.getElementById("nom").style.border = "4px double red";
            }
            if (email === "" || !isEmailValid(email)) {
                document.getElementById("email").style.border = "4px double red";
            }
            if (objet === "") {
                document.getElementById("objet").style.border = "4px double red";
            }
            if (message === "") {
                document.getElementById("message").style.border = "4px double red";
            }

            if (valdata == false) {
              document.getElementById("valdata").style.boxShadow = "0px 0px 0px 2px red";
            }

            // Ajout de la div avec le texte
            let warningMessage = "Veuillez compléter tous les champs obligatoires.";
            if (email !== "" && !isEmailValid(email) && nom !== "" && email !== "" && objet !== "" && message !== "" && valdata == true) {
                warningMessage = "L'email renseigné n'est pas dans un format valide.";
            }
            displayWarningMessage(warningMessage);
        }
    };

    const sendDataToServer = (data) => {
        fetch('http://127.0.0.1:8000/envoyer-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Définir le type de contenu comme JSON
            },
            body: JSON.stringify(data) // Convertir les données en JSON
        })
            .then(async response => {
                if (response.ok) {
                  const responseData = await response.text(); // Attendre la résolution de la promesse
                  alert(responseData); // Afficher le contenu de la réponse
                  document.getElementById('FormulaireContact').reset();
                  
                  // Réinitialiser les bordures rouges des champs
                  document.getElementById("nom").style.border = "none";
                  document.getElementById("prenom").style.border = "none";
                  document.getElementById("email").style.border = "none";
                  document.getElementById("objet").style.border = "none";
                  document.getElementById("message").style.border = "none";

                  // Réinitialiser l'ombre rouge de la case à cocher
                  document.getElementById("valdata").style.boxShadow = "none";
                  supprimerWarningMessage();
                } else {
                  const responseData = await response.text(); // Attendre la résolution de la promesse
                  alert(responseData); // Afficher le contenu de la réponse
                }
            })
            .catch(error => {
                console.error('Erreur lors de l\'envoi de l\'email:', error);
                alert('Erreur lors de l\'envoi de l\'email. Veuillez réessayer.');
            });
    };

    const isEmailValid = (email) => {
        // Expression régulière pour vérifier si l'email est valide
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const displayWarningMessage = (message) => {
        let warningMessageDiv = document.getElementById("WarningMessage");
        if (warningMessageDiv === null) {
            warningMessageDiv = document.createElement('div');
            warningMessageDiv.id = 'WarningMessage';
            warningMessageDiv.className = 'double-input-div';
            warningMessageDiv.style.flexDirection = 'row';
            warningMessageDiv.style.fontSize = '16px';
            warningMessageDiv.style.color = "red";
            document.getElementById('FormulaireContact').appendChild(warningMessageDiv);
        }
        warningMessageDiv.innerHTML = message;
    };

    function supprimerWarningMessage() {
        const element = document.getElementById("WarningMessage");
        if (element) {
          element.parentNode.removeChild(element);
        }
      }

    return (
        <div id='SectionContact'>
            <div id='ContactContainer'>
                <div id='ContactTitle' className='section-name'><h2>CONTACTEZ-MOI</h2></div>
                <div id='ContactDisclaimer' className='section-name'>N'hésitez pas à me joindre, je vous répondrai dès que possible.</div>
                <div id='FormulaireContactContainer'>
                    <form id='FormulaireContact' onSubmit={handleSubmit}>
                        <div className='simple-input-div'>
                            <label className='simple-input-contact-label'>Nom*</label>
                            <input id="nom" className='simple-input-contact'></input>
                        </div>
                        <div className='simple-input-div'>
                            <label className='simple-input-contact-label'>Prénom</label>
                            <input id="prenom" className='simple-input-contact'></input>
                        </div>
                        <div className='simple-input-div'>
                            <label className='simple-input-contact-label'>E-Mail*</label>
                            <input id="email" className='simple-input-contact'></input>
                        </div>
                        <div className='simple-input-div'>
                            <label className='simple-input-contact-label'>Téléphone</label>
                            <input id="telephone" className='simple-input-contact'></input>
                        </div>
                        <div className='double-input-div'>
                            <label className='simple-input-contact-label'>Objet*</label>
                            <input id="objet" className='simple-input-contact'></input>
                        </div>
                        <div className='double-input-div'>
                            <label className='simple-input-contact-label'>Message*</label>
                            <textarea id="message" className='simple-input-contact' style={{ height: "8rem", resize: "none" }}></textarea>
                        </div>
                        <div className='double-input-div' style={{ flexDirection: "row", fontSize: "16px" }}>
                            <input type='checkbox' id="valdata" style={{ width: "1rem", aspectRatio: "1/1", margin: '0.3rem 0.3rem auto auto' }}></input>
                            En validant ce formulaire, j'accepte l'utilisation de mes données personnelles
                            uniquement pour me recontacter.
                        </div>
                        <div className='double-input-div'>
                            <button type="submit" id='FormulaireContactButtonValidation'>ENVOYER</button>
                        </div>
                    </form>
                    <div id='FormulaireContactInfos'>
                        <div className='formulaire-contact-infos' id='Mail'>
                            <div></div><a href='mailto:chams.tifoura98@gmail.com'>chams.tifoura98@gmail.com</a>
                        </div>
                        <div className='formulaire-contact-infos' id='Telephone'>
                            <div></div><a href='tel:0602181874'>06 02 18 18 74</a>
                        </div>
                        <div className='formulaire-contact-infos' id='Adresse'>
                            <div></div><a>33000 Bordeaux</a>
                        </div>
                        <div className='formulaire-contact-infos' id='Linkedin'>
                            <a href='https://www.linkedin.com/in/chams-eddine-tifoura-153519235/' target='blank'><div></div></a>
                        </div>
                        {/* <div className='formulaire-contact-infos' id='Github'>
                            <a href='https://github.com/GOLDENDUC' target='blank'><div></div></a>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionContact;
