import BoutonTelechargementCV from './BoutonTelechargementCV';
import './Mainpage.css';
import ProgressBar from './ProgressBar';

function SectionCompetences() {
  return (
        <div id='SectionCompetences'>
            <div id='CompetencesContainer'>
                <div id='CompetencesTitle' className='section-name'><h2>MES COMPÉTENCES</h2></div>
                <div id='CompetencesDisclaimer' className='section-name'>(À savoir que ces compétences sont en perpétuelle évolution.)</div>
                <div id='CompetencesInfos'>
                    <div id='CompetencesInfosBarres'>
                        <div className='progress-bar-ensemble'>
                            <div className='progress-bar-ensemble-name'>BACK-END</div>
                            <ProgressBar Name={"PHP"} Progress={90}/>
                            <ProgressBar Name={"Symfony"} Progress={85}/>
                            <ProgressBar Name={"API Platform"} Progress={80}/>
                            <ProgressBar Name={"Symfony"} Progress={85}/>
                            <ProgressBar Name={"JAVA"} Progress={70}/>
                            <ProgressBar Name={"Spring Boot"} Progress={70}/>
                            <ProgressBar Name={"Hibernate"} Progress={65}/>
                            <ProgressBar Name={"C#"} Progress={85}/>
                        </div>
                        <div className='progress-bar-ensemble'>
                            <div className='progress-bar-ensemble-name'>FRONT-END</div>
                            <ProgressBar Name={"HTML"} Progress={95}/>
                            <ProgressBar Name={"CSS"} Progress={95}/>
                            <ProgressBar Name={"Javascript"} Progress={90}/>
                            <ProgressBar Name={"React"} Progress={85}/>
                        </div>
                        <div className='progress-bar-ensemble'>
                            <div className='progress-bar-ensemble-name'>CMS</div>
                            <ProgressBar Name={"Wordpress"} Progress={95}/>
                            <ProgressBar Name={"Prestashop"} Progress={50}/>
                        </div>
                        <div className='progress-bar-ensemble'>
                            <div className='progress-bar-ensemble-name'>AUTRES</div>
                            <ProgressBar Name={"Blender"} Progress={95}/>
                            <ProgressBar Name={"Substance Painter"} Progress={90}/>
                            <ProgressBar Name={"Photoshop"} Progress={90}/>
                        </div>         
                    </div>
                    {/*---------------------------------------------------------------------------- */}
                    <div id='CompetencesInfosCV'>
                        <div id='CompetencesInfosCVPhoto'>
                            <img src="/photos/CV.png"></img>
                        </div>
                        <BoutonTelechargementCV/>
                    </div>   
                </div>

            </div>
        </div>
  );
}

export default SectionCompetences;