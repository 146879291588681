import React, { useState, useEffect } from 'react';
import './Mainpage.css';

function Navbar() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 50, // ajustement de la hauteur de défilement pour aligner avec la section
        behavior: 'smooth' // défilement en douceur
      });
    }
  };

  const showNavbarItemsMobile = () => {
    if(document.getElementById("NavbarMobileItemsContainer").style.display == "none"){
        document.getElementById("NavbarMobileItemsContainer").style.display = "flex";
    } else{
        document.getElementById("NavbarMobileItemsContainer").style.display = "none";
    }
  }

  return (
    <div>
        <div id='Navbar' style={{height: scrollPosition >= 140 ? '100px' : '140px'}}>
            <div className='navbar-back' style={{backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.398), rgba(0, 0, 0, 0.297), rgba(0, 0, 0, 0.215), rgba(0, 0, 0, 0.149), rgba(0, 0, 0, 0.098), rgba(0, 0, 0, 0.061), rgba(0, 0, 0, 0.036), rgba(0, 0, 0, 0.019), rgba(0, 0, 0, 0))"       
                , opacity: scrollPosition >= 140 ? '0' : ''}}></div>
            <div className='navbar-back' style={{background: scrollPosition >= 140 ? 'rgb(0 0 0 / 43%)' : '#00000000', backdropFilter: scrollPosition >= 140 ? "blur(4px)" : "blur(0px)"}}></div>
            <div className='navbar-back'>
                <div id='NavbarItemContainer'>
                    <a className='navbar-item' style={{color: scrollPosition >= 140 ? '#ffffff' : ''}} onClick={() => scrollToSection('SectionHeader')}>Accueil</a>
                    <a className='navbar-item' style={{color: scrollPosition >= 140 ? '#ffffff' : ''}} onClick={() => scrollToSection('SectionResume')}>Profil</a>
                    <a className='navbar-item' style={{color: scrollPosition >= 140 ? '#ffffff' : ''}} onClick={() => scrollToSection('SectionCompetences')}>Compétences</a>
                    <a className='navbar-item' style={{color: "#9f9f9f", cursor: "auto"}} onClick={() => scrollToSection('portfolio')}>Portfolio</a>
                    <a className='navbar-item' style={{color: scrollPosition >= 140 ? '#ffffff' : ''}} onClick={() => scrollToSection('SectionContact')}>Contact</a>
                </div>
            </div>
        </div>
        <div id='NavbarMobile'>
            <div id='NavbarMobileButton' onClick={() => showNavbarItemsMobile()}></div>
        </div>
        <div id='NavbarMobileItemsContainer'>
            <a className='navbar-item-mobile' onClick={() => scrollToSection('SectionHeader')}>Accueil</a>
            <a className='navbar-item-mobile' onClick={() => scrollToSection('SectionResume')}>Profil</a>
            <a className='navbar-item-mobile' onClick={() => scrollToSection('SectionCompetences')}>Compétences</a>
            <a className='navbar-item-mobile' onClick={() => scrollToSection('portfolio')}>Portfolio</a>
            <a className='navbar-item-mobile' onClick={() => scrollToSection('SectionContact')}>Contact</a>
        </div>
    </div>
  );
}

export default Navbar;
