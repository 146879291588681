import React from 'react';
import './Mainpage.css';

function BoutonTelechargementCV() {
  const handleTelechargement = () => {
    // Remplacez "chemin/vers/ton/document.pdf" par le chemin de votre document
    const cheminDocument = "/photos/CV.png";
    const nomDocument = "CV.png";

    // Crée un élément <a> invisible pour le téléchargement
    const lienTelechargement = document.createElement("a");
    lienTelechargement.href = cheminDocument;
    lienTelechargement.download = nomDocument;
    lienTelechargement.click();
  };

  return (
    <button onClick={handleTelechargement} id='ButtonCV'><div className='button-section'>Télécharger le CV</div><div className='button-section-2'></div></button>
  );
}

export default BoutonTelechargementCV;
