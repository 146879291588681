import './Mainpage.css';

function Footer() {
  return (
        <div id='Footer'>
            <div id='FooterInfos'>© Copyright 2024 - Chams-Eddine Tifoura. Tous droits réservés.</div>
        </div>
  );
}

export default Footer;