import logo from './logo.svg';
import './Mainpage.css';
import Mainpage from './Mainpage.js';

function App() {
  return (
    <Mainpage/>
  );
}

export default App;
