import React, { useState, useEffect } from 'react';
import './Mainpage.css';
import PDP from './PDP.js';

function SectionHeader() {

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToSection = (id) => {
      const section = document.getElementById(id);
      if (section) {
        window.scrollTo({
          top: section.offsetTop - 50, // ajustement de la hauteur de défilement pour aligner avec la section
          behavior: 'smooth' // défilement en douceur
        });
      }
    };

  return (
        <div id='SectionHeader'>
            <div id='PhotoProfil'>
                <div id='BarreNoireDeco'></div>
                <div id="CadrePhoto" style={{height:"220px", width:"220px", backgroundColor: "white"}}></div>
                <div id="CadrePhoto"></div>
                <PDP/>
            </div>
            <div id='Titles'>
                <div id="TitleInfos">
                    <div id='Nom'>
                        <h1>Chams-Eddine <br/>Tifoura</h1>
                    </div>
                    <div id='Metier'>
                        <h2 style={{  fontWeight: "100"}}>Développeur Web - Intégrateur</h2>
                    </div>
                    <div>
                        <a id='ButtonInfo' onClick={() => scrollToSection('SectionResume')}>
                            <div>
                                QUI SUIS-JE ?
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default SectionHeader;