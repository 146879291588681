import React, { useEffect, useState, useRef } from 'react';
import './Mainpage.css';

function ProgressBar({ Name, Progress }) {
    const [isVisible, setIsVisible] = useState(false);
    const progressBarRef = useRef(null);

    useEffect(() => {
        const progressBar = progressBarRef.current;

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5 // Détecter lorsque 50% de l'élément est visible
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, options);

        observer.observe(progressBar);

        return () => {
            observer.unobserve(progressBar);
        };
    }, []);

    return (
        <div className='progress-bar-container' ref={progressBarRef}>
            <div className='progress-bar-name'>{Name}</div>
            <div className='progress-bar-back'>
                <div className='progress-bar' style={{ width: isVisible ? `${Progress}%` : '0%' }}></div>
            </div>
        </div>
    );
}

export default ProgressBar;
