import Footer from './Footer.js';
import './Mainpage.css';
import Navbar from './Navbar.js';
import PDP from './PDP.js';
import SectionCompetences from './SectionCompetences.js';
import SectionContact from './SectionContact.js';
import SectionHeader from "./SectionHeader.js"
import SectionResume from './SectionResume.js';

function Mainpage() {
  return (
    <div className='main'>
        <Navbar/>
        <SectionHeader/>
        <SectionResume/>
        <SectionCompetences/>
        <SectionContact/>
        <Footer/>
    </div>
  );
}

export default Mainpage;