import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass';

const PDP = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    let scene, camera, renderer, fbxModel;

    // Créer la scène
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000); // Fond noir
    scene.background = null;

    // Ajouter une lumière ambiante
    const ambientLight = new THREE.AmbientLight(0xffffff, 1); // Couleur blanche, intensité 1
    scene.add(ambientLight);

    // Créer la caméra
    camera = new THREE.PerspectiveCamera(12, 1, 0.1, 1000); // Ratio 1:1
    camera.position.z = 20; // Dézoomer la caméra

    // Créer le rendu avec un canal alpha
    renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(250, 250); // Taille de la div
    containerRef.current.appendChild(renderer.domElement);

    // Rendre l'arrière-plan transparent
    renderer.setClearColor(0xffffff, 0); // Couleur noire, transparence totale

    // Charger le modèle FBX
    const loader = new FBXLoader();
    loader.load('/3D/PhotoDeProfil.fbx', (fbx) => {
      fbxModel = fbx;
      
      // Charger et appliquer les textures
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load('/3D/texture.png', (texture) => {
        fbxModel.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = new THREE.MeshBasicMaterial({ map: texture }); // Utilisation de MeshBasicMaterial pour ignorer les ombres
          }
        });
      });

      scene.add(fbxModel);

      // Animation de rotation du modèle uniquement sur l'axe y
      const animate = () => {
        requestAnimationFrame(animate);
        fbxModel.rotation.y += 0.01; // Rotation seulement autour de l'axe y
        renderer.render(scene, camera);
      };

      animate();
    });

    // Gestion du redimensionnement de la fenêtre
    const handleResize = () => {
      const { clientWidth, clientHeight } = containerRef.current;
      camera.aspect = clientWidth / clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(clientWidth, clientHeight);
    };

    window.addEventListener('resize', handleResize);

    // Nettoyage des ressources lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
      containerRef.current.removeChild(renderer.domElement);
      renderer.dispose();
    };
  }, []);

  return <div ref={containerRef} style={{ width: '250px', height: '250px',  filter: "drop-shadow(0 0 0.75rem rgb(0, 0, 0))"}} />;
};

export default PDP;
